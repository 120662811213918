import React, { useState } from 'react'
import { Globe } from '../icons'
import styled from 'styled-components'
import { Popover } from '@material-ui/core'
import { useTranslation, Link, useI18next } from 'gatsby-plugin-react-i18next'
import { black, white } from '../../styling/colors'

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const GlobeWrapper = styled.div`
  padding-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DisplayText = styled.span`
  color: white;
`

const PopoverMessage = styled.div`
  width: 100px;
  height:100px;
`
const LanguageList = styled.ul`
  padding:0;
  margin:0;
  width:100%;
  height:100%;
  list-style:none;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
`
const LanguageListItem = styled.li`
  width:100%;
  height:50%;
  display:flex;
  align-items:center;
  justify-content:center;
  &:hover {
    font-weight:bold;
    font-size:20px;
  }
`
export default function LanguageSwitcher() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const { i18n } = useTranslation()
  const { languages, originalPath } = useI18next()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  return (
    <Container onClick={handleClick}>
      <Popover
        style={{ overflow: 'clip' }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverMessage>
          <LanguageList>
            {languages.map(lng => (
              <LanguageListItem key={lng}>
                <Link to={originalPath} language={lng} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%', textTransform:'uppercase'}}>
                  {lng === 'ka-GE' ? 'GE' : lng}
                </Link>
              </LanguageListItem>
            ))}
          </LanguageList>
        </PopoverMessage>
      </Popover>
      <GlobeWrapper>
        <Globe width={18} height={18}/>
      </GlobeWrapper>
      <DisplayText style={{ textTransform: 'uppercase' }}>
        {i18n.language === 'ka-GE' ? 'GE' : i18n.language}
      </DisplayText>
    </Container>
  )
}

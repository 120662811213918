import React, { useState, useCallback, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { ButtonSend, TextField } from '../'
import { Check } from '../icons'
import SvgFacebookLight from '../icons/transformed/FacebookLight'
import SvgInstagramLight from '../icons/transformed/InstagramLight'
import SvgLinkedinLight from '../icons/transformed/LinkedinLight'
import SvgYoutubeLight from '../icons/transformed/YoutubeLight'
import { When } from '../../styling/when'
import { useMediaMatch, useFormSubmit } from '../../util'
import { ContactPageEmailForm } from '../../interfaces'
import { countries } from 'countries-list'
import { navigate } from '@reach/router'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const countriesList = Object.values(countries)
  .map(e => ({ name: e.name, phoneCode: e.phone }))
  .sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
  })

const ThinArrow = props => {
  return (
    <svg
      width={93}
      height={14}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_thin_arrow)">
        <path
          d="M89.97 6.91H.75M7.6 13.08l-6.67-6a.22.22 0 010-.33l6.67-6"
          stroke="#AF8D08"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.97 9a2.09 2.09 0 100-4.18 2.09 2.09 0 000 4.18z"
          fill="#AF8D08"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_thin_arrow">
          <path fill="#fff" d="M0 0h92.05v13.83H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

const LocationIcon = props => {
  return (
    <svg
      width={15}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.48.05a7 7 0 00-7 7c0 4.81 6.29 11.88 6.56 12.18a.63.63 0 00.94 0c.27-.3 6.56-7.37 6.56-12.18a7.001 7.001 0 00-7.06-7zm0 10.57a3.54 3.54 0 113.54-3.54 3.55 3.55 0 01-3.54 3.54z"
        fill="#fff"
      />
    </svg>
  )
}

const TheLine = props => {
  return (
    <svg
      width={228}
      height={81}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.25 1h206.76c10.32 0 18.69 8.85 18.69 19.76v38.35c0 10.91-8.37 19.76-18.69 19.76h-109"
        stroke="#AF8D08"
        strokeWidth={0.75}
        strokeMiterlimit={10}
        strokeLinecap="round"
      />
      <path
        d="M99.612 80.96a2.09 2.09 0 100-4.179 2.09 2.09 0 000 4.179z"
        fill="#AF8D08"
      />
    </svg>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1070px;
  margin: 0 auto;
  ${When.Large} {
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    display: flex;
  }
`
const FormWrapper = styled.form`
  margin: 0 24px;
  margin-bottom: 24px;
  margin-bottom: 50px;

  ${When.Medium} {
    max-width: 544px;
    width: 100%;
    margin: 0;
    margin-bottom: 50px;
  }

  ${When.Large} {
    margin-right: 126px;
  }

  & input,
  & textarea,
  & label {
    font-family: 'Poppins', 'Noto Sans Georgian' !important;
    font-style: normal;
    font-weight: normal;
  }

  & > div:nth-of-type(2) > div:first-of-type {
    margin-bottom: 20px;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 20px !important;
  }

  ${When.Medium} {
    & > div:nth-of-type(2) > div:first-of-type {
      margin-bottom: unset;
    }

    & > div:not(:last-of-type) {
      margin-bottom: 40px !important;
    }
  }
`
const StyledTextField = styled(TextField)`
  ${({ name }) =>
    name === 'phone'
      ? `& label:not(.MuiInputLabel-shrink) {
      margin-left: 55px;
    }
    & input {
      padding-left: 55px;
    }
    `
      : null};

  ${({ expandOnMobile }) =>
    expandOnMobile
      ? `
padding: 0px;
margin: 0;
max-width: 544px;
width: 100%;
& textarea {
color: #ffffff;
}
${When.Medium} {
  padding: 0px;
  margin: 0;
  max-width: 300px;
  width: 100%;
}
}
`
      : `
    padding: 0px;
    margin: 0;
    max-width: 300px;
    width: 100%;
  }
}
`};

  & .MuiInput-underline::before {
    border-bottom: 1px solid #af8d08e6 !important;
  }

  & .MuiInput-underline::after {
    border-bottom: 2px solid #af8d08 !important;
  }
`
const StyledTextField2 = styled(TextField)`
  padding: 0px;
  margin: 0;
  max-width: 544px;
  width: 100%;
  & textarea {
    color: #ffffff;
  }

  & .MuiInput-underline::before {
    border-bottom: 1px solid #af8d08e6 !important;
  }

  & .MuiInput-underline::after {
    border-bottom: 2px solid #af8d08 !important;
  }
`
const WrapperInside = styled.div`
  ${({ expandOnMobile }) =>
    `
  ${expandOnMobile ? When.Medium : When.Short} {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    max-width: 256px;
    width: 100%;
    
    grid-column-gap: 24px;
    &:last-child {
      margin-right: 0;
    }
    
    /* ${When.Medium} {
      margin-right: 0;
    } */
  }
`};
`

const Wrapper2 = styled.div`
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  max-width: 544px;
  width: 100%;
`

const FormLine = styled.div`
  ${({ expandOnMobile }) =>
    `
    ${expandOnMobile ? When.Medium : When.Short} { {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      display: flex;
      justify-content: space-between;
    }
  `};
`
const InfoContainer = styled.div`
  width: 352px;
  min-width: 352px;
  max-width: 352px;
  margin-right: 0px;
  box-sizing: border-box;
  padding-top: 80px;
  padding-left: 60px;
  padding-right: 50px;
  padding-bottom: 32px;
  border-radius: 6px;
  margin-bottom: 20px;
  border: 0.75px solid #af8d08;
`
const Cicle = styled.div`
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  margin-bottom: 8px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const AddressTitle = styled.h3`
  margin: 0px;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 36px;
  margin-bottom: 12px;
  color: #af8d08;
`
const Address = styled.p`
  margin: 0px;
  max-width: 164px;
  width: 100%;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 50px;
  color: #fff;
`
const IconsBox = styled.div`
  display: flex;
`

const SocialLink = styled.a`
  box-sizing: border-box;
  margin-right: 8px;
`

const SendBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 18px;
`
const StyledSendButton = styled.button`
  margin-top: 15px;
  outline: none;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-column-gap: 14px;
  font-size: 14px;
  cursor: pointer;
  & span {
    color: #fff;
  }

  & svg path {
    stroke: #fff;
  }

  & svg path:nth-of-type(2) {
    fill: #fff;
  }
`

const PostMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  margin-top: 100px;
  margin-bottom: 160px;

  ${When.Large} {
    margin: 0;
    margin-right: 250px;
    max-width: 300px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`
const Round = styled.div`
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  border-radius: 50px;
  margin-bottom: 12px;
  background-color: #4dae50;

  display: flex;
  justify-content: center;
  align-items: center;
`

// Sent styles
const YourMessageWasSent = styled.h3`
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  color: white;
  text-align: center;
  ${When.Large} {
    font-size: 24px;
    margin-bottom: 12px;
  }
`
const WeWillGetInTouch = styled.p`
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 6px;
  color: white;

  ${When.Large} {
    font-size: 14px;
    margin-bottom: 12px;
  }
`
const MessageSentSendBox = styled.div`
  margin-top: 28px;
  display: flex;
  align-items: center;
`

const ErrorText = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #f44336;
  margin-top: 5px;
`

const CountryCodeSelector = styled.button`
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  // font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 21px;
  color: #ffffff;
  width: 50px;
  height: 30px;
  margin-top: -30px;
  z-index: 998;
  &:not(:focus) > div {
    display: none;
  }
  border: none;
  outline: none;
  text-align: left;
  padding: 3px;
  position: relative;
  cursor: pointer;
`
const CountryCodeInner = styled.div`
  top: 22px;
  height: 140px;
  margin-top: 6px;
  margin-left: -6px;
  width: 256px;
  background-color: #fff;
  z-index: 100;
  position: absolute;
  border-radius: 0px 0px 4px 4px;
  box-sizing: border-box;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
  }
`

const CountryCode = styled.div`
  display: flex;
  height: 28px;
  background-color: #fff;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 49px;
  &:hover {
    background: #e0e0e0;
  }
  cursor: pointer;
`

const CountryCodeCountry = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  max-width: 140px;
  text-align: left;
`
const CountryCodeCode = styled.span`
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #212121;
`

const SendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  ${When.Large} {
    margin-right: -100px;
  }
`

const SendButtonText = styled.span`
  display: inline-block;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #af8d08;
  margin-right: 14px;
`

const StyledThinArrow = styled(ThinArrow)`
  transform: rotate(180deg);
`

const StyledLocationIcon = styled(LocationIcon)``

const StyledTheLine = styled(TheLine)`
  position: absolute;
  top: 18px;
  left: 10px;
`

const LocationIconAndLineContainer = styled.div`
  position: relative;
  margin-bottom: 15px;
`

const ContactTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 25px;
  margin-top: ${({ lang }) => (lang === "ka-GE" ? "-76px" : "-66px")};

`

const ContactTitlePart1 = styled.h2`
  margin: 0;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  text-align: center;
  color: #212121;
  margin-bottom: ${({ lang }) => (lang === "ka-GE" ? "10px" : "0")};
`

const ContactTitlePart2 = styled.h2`
  margin: 0;
  font-family: 'Poppins', 'Noto Sans Georgian';
  font-style: normal;
  font-weight: 500;
  font-size: 42px;
  text-align: center;
  color: #af8d08;
`

export default function Contacts({
  emailType = 'contact-us',
  additionalData = {},
}) {
  const [viewState, setViewState] = useState<
    'initial' | 'message' | 'post-message'
  >('initial')

  const { large } = useMediaMatch()
  const { t, i18n } = useTranslation()

  const {
    sendContactUsEmail,
    contactEmailResponseLoading,
    sendContactUsAboutBottleEmail,
    productEmailResponseLoading,
  } = useFormSubmit()

  const { isLoading, sendEmail } = useMemo(() => {
    switch (emailType) {
      case 'contact-us':
        return {
          sendEmail: sendContactUsEmail,
          isLoading: contactEmailResponseLoading,
        }
      case 'about-bottle':
        return {
          sendEmail: sendContactUsAboutBottleEmail,
          isLoading: contactEmailResponseLoading,
        }
      default:
        return {
          sendEmail: sendContactUsEmail,
          isLoading: contactEmailResponseLoading,
        }
    }
  }, [contactEmailResponseLoading, productEmailResponseLoading, emailType])

  const {
    allStrapiSocialLinks: {
      edges: [{ node: SocialLinksURLs }],
    },
  } = useStaticQuery(query)

  const [errors, setErrors] = useState({})
  const [selectedCountryCode, setSelectedCountryCode] = useState('995')

  const handleCountryCodeClick = country => {
    console.log(country)
    setSelectedCountryCode(country.phoneCode)
    document.getElementById('country-code-selector').blur()
  }

  const submit = useCallback(
    async e => {
      const contactFormValidator = values => {
        const errors = {}
        let foundError = false
        const entries = Object.entries(values)
        for (let [key, value] of entries) {
          if (
            !value ||
            (key === 'email' &&
              !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g.test(
                value
              )) ||
            (key === 'phone' && value.length < 9)
          ) {
            foundError = true
            errors[key] = t('ContactError')
          }
        }
        if (foundError) {
          return errors
        } else {
          return true
        }
      }
      e.preventDefault()
      if (isLoading) {
        return
      }
      const formValues: ContactPageEmailForm = {
        fullName: e.target.fullName.value,
        email: e.target.email.value,
        phone: e.target.phone.value,
        subject: e.target.subject.value,
        message: e.target.message.value,
      }

      const errors = contactFormValidator(formValues)
      if (errors !== true) {
        setErrors(errors)
        console.log(errors)
        return
      } else {
        formValues.phone = '+' + selectedCountryCode + ' ' + formValues.phone
        setErrors({})
      }

      console.log(
        {
          ...formValues,
          ...additionalData,
        },
        emailType
      )

      const success: boolean = await sendEmail({
        ...formValues,
        ...additionalData,
      })

      if (true) {
        navigate('/thank-you')
      }
    },
    [isLoading, sendEmail]
  )

  const handleCountryCodeKeyDown = e => {
    const letter = String.fromCharCode(e.keyCode)
    console.log(letter)
    const countryCodeList = document.getElementById('country-code-list')
    for (let childNode of countryCodeList.childNodes) {
      if (childNode.childNodes[0].innerHTML.startsWith(letter)) {
        console.log(childNode.childNodes[0].innerHTML)
        console.log(childNode.getBoundingClientRect())
        countryCodeList.scrollTo(0, childNode.offsetTop)
        break
      }
    }
  }

  return (
    <Container>
      <ContactTitleContainer lang={i18n.language}>
        <ContactTitlePart1 lang={i18n.language}>{t('ContactForm')}</ContactTitlePart1>
        <ContactTitlePart2>{t('UsForm')}</ContactTitlePart2>
      </ContactTitleContainer>
      <Wrapper>
        {viewState !== 'post-message' && (
          <FormWrapper onSubmit={submit}>
            <Wrapper2>
              <StyledTextField2
                name="fullName"
                label={t('FullName')}
                error={errors.fullName}
              />
              {errors.fullName ? (
                <ErrorText> {errors.fullName} {t('FullName')}</ErrorText>
              ) : null}
            </Wrapper2>

            <FormLine expandOnMobile>
              <WrapperInside expandOnMobile>
                <StyledTextField
                  expandOnMobile
                  name="email"
                  label={t('EmailAddress')}
                  error={errors.email}
                />
                {errors.email ? (
                  <ErrorText> {errors.email} {t('EmailAddress')}</ErrorText>
                ) : null}
              </WrapperInside>

              <WrapperInside expandOnMobile>
                <StyledTextField
                  expandOnMobile
                  name="phone"
                  label={t('PhoneNumber')}
                  error={errors.phone}
                />
                <CountryCodeSelector
                  type="button"
                  id="country-code-selector"
                  onClick={e => {
                    e.target.focus()
                    document.removeEventListener(
                      'keydown',
                      handleCountryCodeKeyDown
                    )
                    document.addEventListener(
                      'keydown',
                      handleCountryCodeKeyDown
                    )
                  }}
                >
                  {selectedCountryCode}
                  <svg
                    style={{ marginTop: '8px' }}
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 1.5L4 4.5L1 1.5"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <CountryCodeInner
                    id="country-code-list"
                    onMouseDown={e => e.preventDefault()}
                  >
                    {countriesList.map(country => {
                      return (
                        <CountryCode
                          key={country.name}
                          id={country.name}
                          selected={country.phoneCode === selectedCountryCode}
                          onClick={() => handleCountryCodeClick(country)}
                        >
                          <CountryCodeCountry>
                            {country.name}
                          </CountryCodeCountry>
                          <CountryCodeCode>{country.phoneCode}</CountryCodeCode>
                        </CountryCode>
                      )
                    })}
                  </CountryCodeInner>
                </CountryCodeSelector>
                {errors.phone ? (
                  <ErrorText> {errors.phone} {t('PhoneNumber')}</ErrorText>
                ) : null}
              </WrapperInside>
            </FormLine>

            <Wrapper2>
              <StyledTextField2
                name="subject"
                label={t('Subject')}
                error={errors.subject}
              />
              {errors.subject ? (
                <ErrorText> {errors.subject} {t('Subject')}</ErrorText>
              ) : null}
            </Wrapper2>

            <Wrapper2>
              <StyledTextField2
                name="message"
                label={t('Message')}
                multiline={true}
                inputProps={{
                  spellCheck: 'false',
                }}
                error={errors.message}
              />
              {errors.message ? (
                <ErrorText> {errors.message} {t('Message')}</ErrorText>
              ) : null}
            </Wrapper2>

            <SendBox>
              <SendButton type="submit">
                <SendButtonText>{t('Submit')}</SendButtonText>
                <StyledThinArrow />
              </SendButton>
            </SendBox>
          </FormWrapper>
        )}

        {viewState === 'post-message' && (
          <PostMessageWrapper>
            <Round>
              <Check />
            </Round>
            <YourMessageWasSent>{t('MessageSent')}</YourMessageWasSent>
            <WeWillGetInTouch>
              {t('MessageSent2')}
            </WeWillGetInTouch>
            <MessageSentSendBox>
              <StyledSendButton onClick={() => setViewState('message')}>
                <span> {t('MessageSent3')}</span>
                <StyledThinArrow />
              </StyledSendButton>
            </MessageSentSendBox>
          </PostMessageWrapper>
        )}

        {(large || viewState === 'initial' || viewState === 'post-message') && (
          <InfoContainer>
            <LocationIconAndLineContainer>
              <StyledLocationIcon />
              <StyledTheLine />
            </LocationIconAndLineContainer>
            <AddressTitle>{t('Address')}</AddressTitle>
            <Address>
              <div>{t('Office')} #1</div>
              <div>21 {t('SakandelidzeStreet')}</div>
              <div>{t('Tbilisi')} 0162, {t('Georgia')}</div>
              <div style={{ marginTop: 10 }}>+995 555 93 99 77</div>
            </Address>

            <IconsBox>
              <SocialLink href={SocialLinksURLs.instagram} target="_blank">
                <SvgInstagramLight />
              </SocialLink>
              <SocialLink href={SocialLinksURLs.facebook} target="_blank">
                <SvgFacebookLight />
              </SocialLink>
              <SocialLink href={SocialLinksURLs.linkedin} target="_blank">
                <SvgLinkedinLight />
              </SocialLink>
              <SocialLink href={SocialLinksURLs.youtube} target="_blank">
                <SvgYoutubeLight />
              </SocialLink>
            </IconsBox>
          </InfoContainer>
        )}
      </Wrapper>
    </Container>
  )
}

export const query = graphql`
  query {
    allStrapiSocialLinks {
      edges {
        node {
          facebook
          instagram
          linkedin
          youtube
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
